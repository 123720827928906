handleFOUC();

$(document).ready(function () {
  handleEngagementLeaderBoard();
  handleLegacyMoreButtons();
  handleProfileItems();
  trimEmptyWidgetsToPreventStyling();
  handleInlineHTMLEditButtonPositioning();
  handleProfilePageTitleReplacement();
  handlePoweredByLink();
  handleEmptyHomepageRows();
  handleMemberHomepage();
  pageContentLoaded();
  window.onscroll = function () {
    shrinkMenuOnScroll();
  };
});

//Page Loaded + FOUC functions

function handleFOUC() {
  //Class to allow applying of background-color styling in css on html tag (if desired) without affecting tinymce editor styling
  $('html').addClass('html-bg-color').attr('style', 'background-color: #fff;');

  //Both need to be "visibility: hidden", not hide() and/or "display:none" due to default load order/speeds
  $('body').css('visibility', 'visible').hide().fadeIn('slow');
  $('#MPOuterMost').css('visibility', 'hidden').hide().fadeIn('slow');

  $('html').removeAttr('style');
}

function pageContentLoaded() {
  $('body').css('visibility', 'visible').fadeIn('slow');
  $('#MPOuterMost')
    .css('visibility', 'visible')
    .fadeIn('slow', function () {
      $('html').removeAttr('style');
    });
}

function shrinkMenuOnScroll() {
  if ($('#NavLogo_DesktopLogoDiv a').data('useScroll') == 'True') {
    if (document.body.scrollTop > 150 || document.documentElement.scrollTop > 150) {
      $('#NavLogo_PhoneLogoDiv').css('display', 'block');
      $('#NavLogo_DesktopLogoDiv').css('display', 'none');
    } else {
      $('#NavLogo_PhoneLogoDiv').css('display', '');
      $('#NavLogo_DesktopLogoDiv').css('display', '');
    }
  }
}

/****START - non page load definitions***/

function handleEngagementLeaderBoard() {
  //EL all styling is reliant on '.engagement-leaderboard' class being added. I'm only doing it this way because it's "marginally"  better than inline class-wrapping that can be broken if the client deletes the original instance of the tabs control
  $('.memberhome .HLEngagement')
    .closest('.tab-content')
    .closest('.ContentUserControl')
    .addClass('HLLandingControl engagement-leaderboard');

  $('.engagement-leaderboard .HLEngagement ul.display-vertical .points-label').each(function () {
    var pointsLabelText = $(this).text().trim();

    $(this).text(pointsLabelText.replace(' Points', '+'));

    $(this)
      .closest('.row.content-row')
      .siblings('.row.title-row')
      .find('.col-md-9')
      .children()
      .wrapAll('<div class="leaderboard-user-info" />');
    $(this).closest('.row.content-row').siblings('.row.title-row').find('.col-md-9').append($(this));
    $(this).wrap('<div class="leaderboard-points-label" />');
  });

  $('.engagement-leaderboard .tab-content .HLEngagement>.Content ul.display-vertical>li.active-user-row').each(
    function () {
      var profileUrlHref = $(this).find('a[id*=_EngagementList_NameLink_]').attr('href');
      $(this)
        .children()
        .wrapAll('<a href="' + profileUrlHref + '" />');
    },
  );

  $('.engagement-leaderboard .tab-content .HLEngagement>.Content>.bg-warning').each(function () {
    if ($(this).children().length === 0) {
      $(this).text($(this).text().trim());
    }
  });
}

//This fixes an infinite height for FeedWidget onLoad for some reason......
function trimEmptyWidgetsToPreventStyling() {
  $('.ContentUserControl, h2').each(function () {
    if ($(this).children().length === 0) {
      $(this).text($(this).text().trim());
    }
  });
  $('.row > div[class*=col-md-]').each(function () {
    if ($(this).children().length === 0) {
      $(this).text($(this).text().trim());
    }
  });

  $('.ContentItemReact:not(.LibraryEntriesDashboard) > .row.heading > div[id^=ContentTitle_]').each(function () {
    if ($(this).children().length === 0) {
      $(this).parent('.row.heading').text('');
    }
  });
}

function handleInlineHTMLEditButtonPositioning() {
  //This should be globally fixed
  $('.ContentItemHtml').each(function () {
    if ($(this).find('> .inline-content-html-editor-container').length > 0) {
      $(this).addClass('inline-editing-enabled');
    }
  });
}

function handleProfilePageTitleReplacement() {
  $('body.ribbit.profile #PageTitleH1').wrap('<div class="profileUserInfoNavContainer" />');
  $('body.ribbit.profile .profileUserInfoNavContainer').wrap('<div class="profilePageTitleH1Container" />');

  var profileUserName = $('body.ribbit.profile .ProfileUserName > span').first().text();
  if (profileUserName.length > 0) {
    $('body.ribbit.profile #MPContentArea').find('#InteriorPageTitle #PageTitleH1').text(profileUserName);
  }
  var editProfile = $('body.ribbit.profile .ProfileUserName').find('[aria-label="edit username"]');
  if (editProfile.length) {
    $('body.ribbit.profile .profileUserInfoNavContainer #PageTitleH1').append(editProfile);
  }

  $('body.ribbit.profile .profileUserInfoNavContainer').append(
    $('div[id*=_DisplayPresentJob1_UpdatePanel] > .ProfileJobInfo'),
  );
  $('body.ribbit.profile .profileUserInfoNavContainer').append($('div[id$=_ImpersonateTitleContainer]').first());

  if (window.innerWidth <= 768) {
    $('div[id$=_upnlProfileImage] button').html('<i class="fas fa-camera"></i>');
    $('body.ribbit.profile div[id$=_upnlProfileImage]').prependTo('body.ribbit.profile .profilePageTitleH1Container');
    var activeTab;
    if ($('ul[id$=_ProfileTabList] li.active > a').length > 1) {
      activeTab = $('ul[id$=_ProfileTabList] li.active li.active > a').text();
    } else {
      activeTab = $('ul[id$=_ProfileTabList] li.active > a').text();
    }

    $('body.ribbit.profile ul[id$=_ProfileTabList]').wrap('<div class="btn-group profile-btn-group" />');
    $('body.ribbit.profile ul[id$=_ProfileTabList]').addClass('collapse profile-tabs-collapse');
    $('body.ribbit.profile ul[id$=_ProfileTabList]').before(
      '<button type="button" class="profile-tabs-toggle" data-toggle="collapse" data-target=".profile-tabs-collapse"><span class="sr-only">Toggle navigation</span>' +
        activeTab +
        '<span class="caret"></span></button>',
    );
    $('body.ribbit.profile ul[id$=_ProfileTabList]')
      .closest('.ContentUserControl')
      .appendTo('body.ribbit.profile .profileUserInfoNavContainer');
  }
}

function handleProfileItems() {
  function handleProfileSendMessageButton() {
    $('div[id$=_ContactCommunications_SmallContainer] div[id$=_ContactCommunications_btnFollowGroup]').html(
      $('div[id$=_ContactCommunications_SmallContainer] li[id$=_ContactCommunications_SendMessageSmallContainer]'),
    );
    $(
      'div[id$=_ContactCommunications_SmallContainer] div[id$=_ContactCommunications_btnFollowGroup] li[id$=_ContactCommunications_SendMessageSmallContainer]',
    ).addClass('btn-group');
    $(
      'div[id$=_ContactCommunications_SmallContainer] div[id$=_ContactCommunications_btnFollowGroup] li[id$=_ContactCommunications_SendMessageSmallContainer] > a',
    ).addClass('btn btn-default btn-sm');
  }

  handleProfileSendMessageButton();

  var prm = Sys.WebForms.PageRequestManager.getInstance();
  prm.add_endRequest(handleProfileSendMessageButton);
}

function handlePoweredByLink() {
  $('#MPFooterLink').appendTo($('#MPOuterFooter'));
}

function handleMemberHomepage() {
  if ($('body').hasClass('memberhome')) {
    $('body').removeClass('interior');
  }
}

function handleLegacyMoreButtons() {
  $(
    'body.ribbit:not(.interior) #MainCopy_ContentWrapper>.row>div[class*=col-md-]>div[class*=Content] .HLLandingControl:not(.home-page-list, .RibbitEventList) div[id$=BottomLink] a',
  ).each(function () {
    if ($(this).find('i').length === 0) {
      $(this).append('<i class="fas fa-long-arrow-right"></i>');
    }
  });
  $(
    'body.ribbit:not(.interior) #MainCopy_ContentWrapper>.row>div[class*=col-md-]>div[class*=Content] .SearchResults div[id$=_pnlMoreLink] a',
  ).each(function () {
    if ($(this).find('i').length === 0) {
      $(this).append('<i class="fas fa-long-arrow-right"></i>');
    }
  });
}

function handleEmptyHomepageRows() {
  var emptyElementCounter = 0;
  $('body.ribbit:not(.interior) #MainCopy_ContentWrapper>.row>div[class*=col-md-]').each(function () {
    var parentElement = $(this).parent();
    var numberOfImmediateChildren = parentElement.children().length;
    if ($(this).is(':empty')) {
      emptyElementCounter++;
    }
    if (numberOfImmediateChildren === emptyElementCounter) {
      emptyElementCounter = 0;
      parentElement.remove();
    }
  });
}
